@import "../../../../../_metronic/_assets/sass/config";

.input-modal-update-password {
  width: 90px;
}

.container-modal {
  display: flex;
  justify-content: left;
  align-items: flex-start;
  flex-direction: column;

  height: fit-content;
  width: 450px;
  margin: 0 auto;

  background-color: kt-get($kt-state-colors, light, base);
  border-radius: 15px;

  span {
    display: block;
  }

  .title {
    text-align: left;
    font-family: "Cera Pro Medium", sans-serif;
    font-weight: 600;   
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.75px;
    color: #4672C5;
  }

  .subtitle {
    font-family: "Cera Pro Light", sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    color: #4d4c4d;
  }

  button.submit,
  button.cancel {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    width: 100%;
    max-width: 100px;
    margin: 0 !important;
    border-radius: 4px;
    border: 1px solid #4672C5;
    background: transparent;

    font-family: "Cera Pro Medium", sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #4672C5;

    box-shadow: none;

    &:disabled {
      opacity: 0.8;
      cursor: not-allowed;
    }

    &.primary {
      background: #4672C5;
      color: #fff;
      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14),
        0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    }
  }
}
